import React, { useEffect, useState } from 'react';
import ServiceHandler from '../services/service-handler';
import { Experiment } from '@amplitude/experiment-js-client';
import * as amplitude from '@amplitude/analytics-browser';
export var FeatureFlagContext = React.createContext({
    payload: null,
});
export var FeatureFlagContextProvider = function (_a) {
    var children = _a.children;
    var cfg = ServiceHandler.ConfigService;
    var _b = useState(null), featureFlags = _b[0], setFeatureFlags = _b[1];
    var _c = useState(false), loaded = _c[0], setLoaded = _c[1];
    useEffect(function () {
        amplitude.init(cfg.AMPLITUDE_API_KEY, { serverUrl: cfg.AMPLITUDE_URL, defaultTracking: false });
        var experimentClient = Experiment.initialize(ServiceHandler.ConfigService.AMPLITUDE_EXPERIMENT_API_KEY);
        var user = {
            user_id: amplitude.getUserId(),
            device_id: amplitude.getDeviceId(),
        };
        experimentClient
            .fetch(user)
            .then(function () {
            var _a;
            var allFlags = experimentClient.all();
            var specificFlags = {};
            for (var _i = 0, _b = Object.entries(allFlags); _i < _b.length; _i++) {
                var _c = _b[_i], flag = _c[0], entry = _c[1];
                specificFlags[flag] = (_a = entry === null || entry === void 0 ? void 0 : entry.payload[cfg.BRAND_NAME][cfg.LANGUAGE_COUNTRY_CODE]) === null || _a === void 0 ? void 0 : _a.flag;
            }
            setFeatureFlags(specificFlags);
        })
            .catch(function () { return setFeatureFlags({}); });
    }, []);
    useEffect(function () {
        if (featureFlags) {
            setLoaded(true);
        }
    }, [featureFlags]);
    var getFlag = function (flag) {
        return featureFlags[flag];
    };
    return loaded && React.createElement(FeatureFlagContext.Provider, { value: [{ getFlag: getFlag }] }, children);
};
