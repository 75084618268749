import React, { useContext } from 'react';
import { useContent } from '../../hooks/use-content';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
// Load views for different brands
//////////////////////
/////////////////////////////////////////////////////////////////////////////////////////
/////////
import styles from './fpr-overview-view.module.scss'; // eslint-disable-line no-redeclare
//////////
import cx from '../../utils/classnames';
import common from '../../utils/common.module.scss';
import utils from '../../utils/utils.module.scss';
import ServiceHandler from '../../services/service-handler';
import { CtaButton, CtaLink, CtaWhiteButton } from '../../components/common/cta/cta';
import { PointsHeroContext } from '../../context/points-hero-context';
import { DangerousHtml } from '../../components/common/dangerous-html/dangerous-html';
import { footiesFPREN } from './footnotes/footnotes-fpr-en';
import { footiesLAREN } from './footnotes/footnotes-lar-en';
import { footiesFPRFR } from './footnotes/footnotes-fpr-fr';
import { footiesLARFR } from './footnotes/footnotes-lar-fr';
import DisclaimerFooter, { renderFootnote } from '../../components/sections/disclaimer-footer/disclaimer-footer';
import PopupIcon from '../../components/common/popup-icons/popup-icons';
import EarnPointsSection from './earn-section/earn-points-section';
var footnotesFPREN = {
    a: footiesFPREN.MODEM,
    b: footiesFPREN.PURCHASE,
    c: footiesFPREN.EARN,
    d: footiesFPREN.REDEEM,
};
var footnotesFPRFR = {
    a: footiesFPRFR.MODEM,
    b: footiesFPRFR.PURCHASE,
    c: footiesFPRFR.EARN,
    d: footiesFPRFR.REDEEM,
};
var footnotesLAREN = {
    a: footiesLAREN.MODEM,
    b: footiesLAREN.PURCHASE,
    c: footiesLAREN.EARN,
    d: footiesLAREN.REDEEM,
};
var footnotesLARFR = {
    a: footiesLARFR.MODEM,
    b: footiesLARFR.PURCHASE,
    c: footiesLARFR.EARN,
    d: footiesLARFR.REDEEM,
};
var OverviewView = function () {
    var _a = useContent('v2/overview-view')[0], content = _a.content, getValueByTitle = _a.getValueByTitle, getHtmlByTitle = _a.getHtmlByTitle;
    var cfg = ServiceHandler.ConfigService;
    var isDesktop = useContext(PointsHeroContext)[0].isDesktop;
    return content ? (React.createElement("div", { className: styles.mainContainer },
        React.createElement("div", { id: 'Header', className: cx(styles.header), style: {
                backgroundImage: "url(".concat(isDesktop ? "".concat(cfg.AEM_BASE_URL).concat(getValueByTitle('hero-image')) : "".concat(cfg.AEM_BASE_URL).concat(getValueByTitle('hero-image-mobile')), ")"),
            } },
            React.createElement("div", { className: cx(styles.headerContent) },
                React.createElement("div", { className: cx(common.h2, styles.headerMainText) }, getValueByTitle('hero-title')),
                React.createElement("div", { className: styles.subheaderContent }, ServiceHandler.AuthenticationService.getIsAuthenticated() ? getValueByTitle('subheader-title-auth') : getValueByTitle('subheader-title')),
                React.createElement("div", { className: cx(common.mT32, styles.flexRow) },
                    React.createElement(CtaWhiteButton, { link: '/dashboard', label: ServiceHandler.AuthenticationService.getIsAuthenticated() ? getValueByTitle('hero-cta-auth') : getValueByTitle('hero-cta-unauth') })))),
        React.createElement(EarnPointsSection, null),
        ServiceHandler.ConfigService.isFPR &&
            React.createElement("div", { id: 'RedeemSectionFPR' },
                React.createElement("div", { className: cx(utils.centerTop, styles.redeemSection) },
                    React.createElement("div", { className: utils.twoColumn },
                        getValueByTitle('redeem-your-points-image') && (React.createElement("div", { className: cx(utils.column, utils.leftColumn, styles.image) },
                            React.createElement("img", { title: 'redeem-your-points-image', src: cfg.AEM_BASE_URL + getValueByTitle('redeem-your-points-image') }))),
                        React.createElement("div", { className: cx(utils.column, styles.content) },
                            React.createElement("h2", { className: cx(styles.title, common.h3) }, getValueByTitle('redeem-your-points-title')),
                            React.createElement(DangerousHtml, { html: getHtmlByTitle('redeem-your-points-subtext'), className: cx(styles.subtext, common.subtext) },
                                React.createElement(PopupIcon, { letter: 'd', id: 'popup-d' },
                                    React.createElement(DangerousHtml, { html: renderFootnote('d', ServiceHandler.ConfigService.isFPR ? ServiceHandler.ConfigService.isEnglish ? footnotesFPREN : footnotesFPRFR : ServiceHandler.ConfigService.isEnglish ? footnotesLAREN : footnotesLARFR) }))),
                            React.createElement("div", { className: cx(common.mT30) },
                                React.createElement(CtaButton, { label: getValueByTitle('view-all-rewards-button'), link: '/rewards' })))))),
        ServiceHandler.ConfigService.isLAR &&
            React.createElement("div", { id: 'RedeemSectionLAR' },
                React.createElement("div", { className: cx(styles.redeemLAR), style: {
                        backgroundImage: "url(".concat(isDesktop ? "".concat(cfg.AEM_BASE_URL).concat(getValueByTitle('redeem-your-points-image')) : "".concat(cfg.AEM_BASE_URL).concat(getValueByTitle('redeem-your-points-image-mobile')), ")"),
                    } },
                    React.createElement("div", { className: cx(styles.redeemLARContent) },
                        React.createElement("div", null,
                            React.createElement("h2", { className: cx(styles.larTitle) }, getValueByTitle('redeem-your-points-title')),
                            React.createElement(DangerousHtml, { html: getHtmlByTitle('redeem-your-points-subtext'), className: cx(styles.larSubtext) },
                                React.createElement(PopupIcon, { letter: 'd', id: 'popup-d' },
                                    React.createElement(DangerousHtml, { html: renderFootnote('d', ServiceHandler.ConfigService.isFPR ? ServiceHandler.ConfigService.isEnglish ? footnotesFPREN : footnotesFPRFR : ServiceHandler.ConfigService.isEnglish ? footnotesLAREN : footnotesLARFR) })))),
                        React.createElement("div", { className: cx(common.mT32) },
                            React.createElement(CtaWhiteButton, { label: getValueByTitle('view-all-rewards-button'), link: '/rewards' }))))),
        React.createElement("div", { id: 'faqs', className: cx(styles.overviewSection, styles.faqs) },
            React.createElement("div", { className: cx(common.h3, styles.overviewSectionTitle) }, getValueByTitle('faq-title')),
            React.createElement("div", { className: styles.faqSub }, getValueByTitle('faq-sub')),
            React.createElement("div", { className: common.mT30 },
                ServiceHandler.ConfigService.isFPR && React.createElement(CtaButton, { label: ServiceHandler.ConfigService.isEnglish ? 'View FAQs' : 'Voir les FAQ', link: '/faqs' }),
                ServiceHandler.ConfigService.isLAR && React.createElement(CtaLink, { label: ServiceHandler.ConfigService.isEnglish ? 'View FAQs' : 'Voir les FAQ', link: '/faqs' }))),
        React.createElement("div", { id: 'dashboard', className: cx(styles.overviewSection, styles.viewDashboard) },
            React.createElement("div", { className: cx(common.h3, styles.overviewSectionTitle) }, getValueByTitle('start-getting-rewarded-title')),
            React.createElement("div", { className: common.mT30 },
                React.createElement(CtaButton, { link: '/dashboard', label: ServiceHandler.AuthenticationService.getIsAuthenticated()
                        ? getValueByTitle('dashboard-auth-cta')
                        : getValueByTitle('dashboard-unauth-cta') }))),
        React.createElement("div", { className: styles.backgroundDisclaimer }, ServiceHandler.ConfigService.isFPR ? ServiceHandler.ConfigService.isEnglish ? React.createElement(DisclaimerFooter, { ids: 'a, b, c, d', footnotes: footnotesFPREN }) : React.createElement(DisclaimerFooter, { ids: 'a, b, c, d', footnotes: footnotesFPRFR }) : ServiceHandler.ConfigService.isEnglish ? React.createElement(DisclaimerFooter, { ids: 'a, b, c, d', footnotes: footnotesLAREN }) : React.createElement(DisclaimerFooter, { ids: 'a, b, c, d', footnotes: footnotesLARFR })))) : (React.createElement(ActivityIndicator, null));
};
export default OverviewView;
