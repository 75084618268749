// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UwnpdTcFD6HC6nJkz5XmVA\\=\\={position:relative;padding:0;margin-right:0;display:inline-block;padding-left:8px;transform-origin:13px;transition:all .5s ease !important}.dsa-2W9mOAdcQulhc3IQxQ\\=\\={transform:rotate(270deg);-webkit-transform:rotate(270deg)}.Fnxrdmh9PlZzQXDx8EpITw\\=\\={transform:rotate(90deg);-webkit-transform:rotate(90deg)}.hJNmmo4BOuAqgcVY9LhVWw\\=\\={transform:rotate(180deg);-webkit-transform:rotate(180deg)}", "",{"version":3,"sources":["webpack://./src/components/common/chevron/fds-chevron.module.scss"],"names":[],"mappings":"AACA,4BACE,iBAAA,CACA,SAAA,CACA,cAAA,CACA,oBAAA,CACA,gBAAA,CACA,qBAAA,CACA,kCAAA,CAGF,4BACE,wBAAA,CACA,gCAAA,CAGF,4BACE,uBAAA,CACA,+BAAA,CAGF,4BACE,wBAAA,CACA,gCAAA","sourcesContent":["@import \"main.scss\";\n.chevronWrap {\n  position: relative;\n  padding: 0;\n  margin-right: 0;\n  display: inline-block;\n  padding-left: 8px;\n  transform-origin: 13px;\n  transition: all 0.5s ease !important;\n}\n\n.up {\n  transform: rotate(270deg);\n  -webkit-transform: rotate(270deg);\n}\n\n.down {\n  transform: rotate(90deg);\n  -webkit-transform: rotate(90deg);\n}\n\n.left {\n  transform: rotate(180deg);\n  -webkit-transform: rotate(180deg);\n}\n\n.right svg {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chevronWrap": "UwnpdTcFD6HC6nJkz5XmVA==",
	"up": "dsa-2W9mOAdcQulhc3IQxQ==",
	"down": "Fnxrdmh9PlZzQXDx8EpITw==",
	"left": "hJNmmo4BOuAqgcVY9LhVWw=="
};
export default ___CSS_LOADER_EXPORT___;
