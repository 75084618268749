var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useContext } from 'react';
import { useContent } from '../../hooks/use-content';
import utils from '../../utils/utils.module.scss';
import cx from '../../utils/classnames';
import common from '../../utils/common.module.scss';
import Breadcrumbs from '../../components/common/breadcrumbs/breadcrumbs';
import { useHistory, useLocation } from 'react-router-dom';
import { PointsAssistContext } from '../../context/points-assist-context';
import { CtaButton } from '../../components/common/cta/cta';
import { DealerLookup, InvoiceInput, VinSelectionByVehicle, ParamEditor, getId, getDesc, submitForReview, SUBMIT_REPAIR, SUBMIT_SALE, DatePicker, } from './points-assist-helper-components';
import styles from './points-assist-view.module.scss';
import ServiceHandler from '../../services/service-handler';
import pointsAssistRouter, { TYPES } from './points-assist-router';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { formatDate } from '../../utils/format.utils';
var PointsAssistReviewView = function () {
    var _a, _b;
    var urlSearchParams = new URLSearchParams(useLocation().search);
    var _c = useContext(PointsAssistContext)[0], dealers = _c.dealers, redirectLookup = _c.redirectLookup;
    var _d = useContent('pointsassist/points-assist')[0], mainContent = _d.content, mainGetValueByTitle = _d.getValueByTitle;
    var _e = useContent('pointsassist/review-no-match-found')[0], defaultContent = _e.content, defaultGetValueByTitle = _e.getValueByTitle;
    var _f = useState(null), chosenDealer = _f[0], setChosenDealer = _f[1]; // eslint-disable-line @typescript-eslint/no-unused-vars
    var _g = useState(null), purchaseDate = _g[0], setPurchaseDate = _g[1];
    var _h = useState(null), invoiceNumber = _h[0], setInvoiceNumber = _h[1]; // eslint-disable-line @typescript-eslint/no-unused-vars
    var _j = useState(''), vin = _j[0], setVin = _j[1]; // eslint-disable-line @typescript-eslint/no-unused-vars
    var _k = useState(false), validate = _k[0], setValidate = _k[1]; // eslint-disable-line @typescript-eslint/no-unused-vars
    var _l = useState(false), disabled = _l[0], setDisabled = _l[1];
    var _m = useContent("pointsassist/review-".concat(pointsAssistRouter(urlSearchParams.get('result'), urlSearchParams.get('invoice') ? TYPES.SERVICE : TYPES.SALE, urlSearchParams.get('retry') ? TYPES.REVIEW : TYPES.INITIAL, redirectLookup)))[0], content = _m.content, getValueByTitle = _m.getValueByTitle, getCtaProps = _m.getCtaProps;
    useEffect(function () {
        setInvoiceNumber(urlSearchParams.get('invoice'));
        setVin(urlSearchParams.get('vin'));
        if (urlSearchParams.get('acquisitionDate'))
            setPurchaseDate(new Date(urlSearchParams.get('acquisitionDate')));
    }, []);
    useEffect(function () {
        var paCode = urlSearchParams.get('paCode');
        if ((dealers === null || dealers === void 0 ? void 0 : dealers.length) && paCode) {
            setChosenDealer(dealers.find(function (d) { return d.paCode == paCode; }));
        }
    }, [dealers]);
    var breadcrumbs = [
        {
            name: 'breadcrumb-1',
            url: '/dashboard',
        },
        {
            name: 'breadcrumb-2',
            url: '/rewards-history',
        },
        {
            name: 'breadcrumb-3',
            url: '/rewards-history/points-assist',
        },
    ];
    var history = useHistory();
    var submitParams = {
        history: history,
        chosenDealer: chosenDealer,
        invoiceNumber: invoiceNumber,
        vin: vin,
        purchaseDate: purchaseDate,
        pending: true,
        setDisabled: setDisabled,
    };
    var retry = urlSearchParams.get('retry') ? false : getValueByTitle('show-retry-section');
    var doSubmit = function () {
        var type = urlSearchParams.get('invoice') ? SUBMIT_REPAIR : SUBMIT_SALE;
        setValidate(true);
        setDisabled(true);
        submitForReview(__assign(__assign({}, submitParams), { type: type, retry: retry }));
    };
    return (React.createElement(React.Fragment, null, content ? (mainContent ? (React.createElement("div", { className: cx(styles.main, styles.reviewContainer) },
        React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs, getValueByTitle: getValueByTitle }),
        React.createElement("div", { className: utils.contentCenter90 },
            React.createElement("div", { className: common.pointsAssistHeading }, getValueByTitle('heading')),
            React.createElement("p", { className: cx((_a = {}, _a[common.pointsAssistDescription] = retry, _a), styles.retryDescription, utils.max800, common.mT30), dangerouslySetInnerHTML: { __html: getValueByTitle('copy') } }),
            ServiceHandler.ConfigService.isLAR && React.createElement("p", { className: cx(styles.retrySubText, utils.max800) }, getValueByTitle('sub-text')),
            React.createElement("div", null,
                React.createElement("div", { className: cx(styles.retrySection, (_b = {}, _b[styles.noMatch] = !retry, _b)) },
                    retry && (React.createElement(ParamEditor, { key: 'dealer', title: mainGetValueByTitle('review-paCode'), value: (chosenDealer === null || chosenDealer === void 0 ? void 0 : chosenDealer.name) || '-' },
                        React.createElement(DealerLookup, { dealer: chosenDealer, setChosenDealer: setChosenDealer, getId: getId, getDesc: getDesc, validate: validate, getValueByTitle: mainGetValueByTitle }))),
                    retry && invoiceNumber && (React.createElement(ParamEditor, { key: 'invoice', title: mainGetValueByTitle('review-invoice'), value: invoiceNumber },
                        React.createElement(InvoiceInput, { invoice: invoiceNumber, setInvoiceNumber: setInvoiceNumber, validate: validate, getValueByTitle: mainGetValueByTitle }))),
                    retry && purchaseDate && (React.createElement(ParamEditor, { key: 'date', title: mainGetValueByTitle('review-acquisitionDate'), value: formatDate(purchaseDate) },
                        React.createElement(DatePicker, { purchaseDate: purchaseDate, setPurchaseDate: setPurchaseDate, validate: validate, getValueByTitle: mainGetValueByTitle }))),
                    retry && (React.createElement(React.Fragment, null,
                        React.createElement(ParamEditor, { key: 'vin', title: mainGetValueByTitle('review-vin'), value: "VIN: ".concat(vin) },
                            React.createElement("br", null),
                            React.createElement("br", null),
                            React.createElement("br", null),
                            React.createElement(VinSelectionByVehicle, { theVin: vin, setVin: setVin, validate: validate, getValueByTitle: mainGetValueByTitle, title: 'select-vehicle' })),
                        React.createElement("div", { className: common.textAlignCenter },
                            React.createElement(CtaButton, __assign({}, getCtaProps('cta'), { onClick: function () { return doSubmit(); }, disabled: disabled, className: cx(common.mT50, styles.confirm, common.redeemButton), allowDblClick: true }))))),
                    !retry && (React.createElement(CtaButton, __assign({}, getCtaProps('cta-return'), { link: '/rewards-history', className: cx(common.mT50, styles.returnCta, common.pointsAssistReturn) }))))),
            getValueByTitle('sub-copy', true) && (React.createElement("div", { className: cx(common.mT50, styles.retryDescription, utils.max850) }, getValueByTitle('sub-copy')))))) : (React.createElement(React.Fragment, null,
        React.createElement(ActivityIndicator, null)))) : (defaultContent &&
        !content && (React.createElement("div", { className: cx(styles.main, styles.reviewContainer) },
        React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs, getValueByTitle: defaultGetValueByTitle }),
        React.createElement("div", { className: utils.contentCenter90 },
            React.createElement("div", { className: common.pointsAssistHeading }, defaultGetValueByTitle('heading')),
            React.createElement("p", { className: cx(styles.retryDescription, utils.max700, common.mT30) }, defaultGetValueByTitle('copy')),
            React.createElement("div", null, !retry && (React.createElement(CtaButton, { id: 'cta-return', label: defaultGetValueByTitle('cta-return'), link: '/rewards-history', className: cx(common.mT50, styles.returnCta, common.pointsAssistReturn), allowDblClick: true })))))))));
};
export default PointsAssistReviewView;
