export function buildQueryParams(params) {
    var qs = [];
    for (var key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            qs.push("".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(params[key])));
        }
    }
    return "?".concat(qs.join('&'));
}
export var getTimeStamp = function () {
    return new Date().getTime().toString(36).toUpperCase();
};
export var generateRandomNum = function () {
    return Math.floor(10000000 + Math.random() * 80000000).toString();
};
export var generateTransactionNum = function (rewardCode) {
    return "".concat(generateRandomNum(), ":").concat(getTimeStamp(), ":").concat(rewardCode);
};
export var isMobileWidth = window.innerWidth < 995;
