import React from 'react';
import { useContent } from '../../../hooks/use-content';
import styles from './earn-points-section.module.scss';
import cx from '../../../utils/classnames';
import common from '../../../utils/common.module.scss';
import utils from '../../../utils/utils.module.scss';
import PopupIcon, { DIRECTION } from '../../../components/common/popup-icons/popup-icons';
import { EarnSection } from './earn-section';
import ServiceHandler from '../../../services/service-handler';
import { DangerousHtml } from '../../../components/common/dangerous-html/dangerous-html';
import { footiesFPREN } from '../footnotes/footnotes-fpr-en';
import { footiesLAREN } from '../footnotes/footnotes-lar-en';
import { footiesFPRFR } from '../footnotes/footnotes-fpr-fr';
import { footiesLARFR } from '../footnotes/footnotes-lar-fr';
import { renderFootnote } from '../../../components/sections/disclaimer-footer/disclaimer-footer';
function EarnPointsSection() {
    var cfg = ServiceHandler.ConfigService;
    var isLAR = cfg === null || cfg === void 0 ? void 0 : cfg.isLAR;
    var getValueByTitle = useContent('v2/overview-earn-section')[0].getValueByTitle;
    var footnotesFPREN = {
        a: footiesFPREN.MODEM,
        b: footiesFPREN.PURCHASE,
        c: footiesFPREN.EARN,
        d: footiesFPREN.REDEEM,
    };
    var footnotesFPRFR = {
        a: footiesFPRFR.MODEM,
        b: footiesFPRFR.PURCHASE,
        c: footiesFPRFR.EARN,
        d: footiesFPRFR.REDEEM,
    };
    var footnotesLAREN = {
        a: footiesLAREN.MODEM,
        b: footiesLAREN.PURCHASE,
        c: footiesLAREN.EARN,
        d: footiesLAREN.REDEEM,
    };
    var footnotesLARFR = {
        a: footiesLARFR.MODEM,
        b: footiesLARFR.PURCHASE,
        c: footiesLARFR.EARN,
        d: footiesLARFR.REDEEM,
    };
    return (React.createElement("div", { id: 'earn', className: styles.earn },
        React.createElement("div", null,
            React.createElement("div", { className: cx(common.h3, styles.earnTitle) }, getValueByTitle('title')),
            React.createElement("div", { className: cx(utils.twoColumn, isLAR ? styles.earnContainerLAR : styles.earnContainer) },
                React.createElement("div", { className: cx(styles.height, styles.mt, isLAR && styles.earnText) },
                    React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('points1-value'), copy: getValueByTitle('points1-copy') },
                        React.createElement(PopupIcon, { letter: 'a', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                            React.createElement(DangerousHtml, { html: renderFootnote('a', ServiceHandler.ConfigService.isFPR ? ServiceHandler.ConfigService.isEnglish ? footnotesFPREN : footnotesFPRFR : ServiceHandler.ConfigService.isEnglish ? footnotesLAREN : footnotesLARFR) }))),
                    React.createElement("br", null),
                    React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('points2-value'), copy: getValueByTitle('points2-copy') },
                        React.createElement(PopupIcon, { letter: 'b', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                            React.createElement(DangerousHtml, { html: renderFootnote('b', ServiceHandler.ConfigService.isFPR ? ServiceHandler.ConfigService.isEnglish ? footnotesFPREN : footnotesFPRFR : ServiceHandler.ConfigService.isEnglish ? footnotesLAREN : footnotesLARFR) })))),
                React.createElement("div", { className: "".concat(styles.earnSectionRight, " ").concat(isLAR && styles.earnText, " ").concat(styles.height, " ").concat(styles.mb) },
                    React.createElement(EarnSection, { isLar: isLAR, points: getValueByTitle('points3-value'), copy: getValueByTitle('points3-copy') },
                        React.createElement(PopupIcon, { letter: 'c', desktopDirection: DIRECTION.RIGHT, id: '', className: "".concat(styles.supLetter, " ").concat(isLAR && styles.supLetterLar) },
                            React.createElement(DangerousHtml, { html: renderFootnote('c', ServiceHandler.ConfigService.isFPR ? ServiceHandler.ConfigService.isEnglish ? footnotesFPREN : footnotesFPRFR : ServiceHandler.ConfigService.isEnglish ? footnotesLAREN : footnotesLARFR) }))),
                    React.createElement("br", null),
                    !isLAR && React.createElement(EarnSection, { className: cx(styles.points4), points: getValueByTitle('points4-value'), copy: getValueByTitle('points4-copy') }),
                    isLAR && React.createElement(EarnSection, { className: cx(styles.points4, (isLAR && styles.pTlar)), title: getValueByTitle('points4-value'), copy: getValueByTitle('points4-copy') }))))));
}
export default EarnPointsSection;
