// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mUwC8nhSsT6954zJhlFovw\\=\\={font-family:FordAntenna,sans-serif;display:block;text-align:center;padding:36px 0}.mUwC8nhSsT6954zJhlFovw\\=\\= a{text-transform:unset}", "",{"version":3,"sources":["webpack://./src/components/sections/terms-conditions-link-view/terms-conditions-link-view.module.scss","webpack://./src/styles/theme-fpr/_fonts.scss"],"names":[],"mappings":"AACA,4BACE,kCCEW,CDDX,aAAA,CACA,iBAAA,CACA,cAAA,CACA,8BACE,oBAAA","sourcesContent":["@import \"main.scss\";\n.container {\n  font-family: $theme-font;\n  display: block;\n  text-align: center;\n  padding: 36px 0;\n  a {\n    text-transform: unset;\n  }\n}\n","$icon-chevron-right: '\\e902';\n$icon-chevron-dot: '\\e901';\n$icon-chevron-right-unfilled: '\\e90a';\n\n$theme-font: FordAntenna, sans-serif;\n$theme-font-reg-italic: FordAntennaItalic, sans-serif;\n$theme-font-light: FordAntennaLight, sans-serif;\n$theme-font-medium: FordAntennaMedium, sans-serif;\n$theme-font-bold: FordAntennaBold, sans-serif;\n$theme-font-semi-bold: FordAntennaSemiBold, sans-serif;\n$theme-font-extra-light: FordAntennaExtraLight, sans-serif;\n$theme-font-condensed-regular: FordAntennaCondensedRegular, sans-serif;\n$theme-font-condensed-bold: FordAntennaCondensedBold, sans-serif;\n$theme-font-condensed-medium: FordAntennaCondensedMedium, sans-serif;\n$theme-font-condensed-light: FordAntennaCondensedLight, sans-serif;\n$theme-font-category-header: FordAntennaBold, sans-serif;\n\n$Lincoln-icon-font: LincolnIcons;\n$fpr-icon-font: FdsFordIcons;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "mUwC8nhSsT6954zJhlFovw=="
};
export default ___CSS_LOADER_EXPORT___;
