var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import React, { useContext, useEffect, useState } from 'react';
import RewardsService from '../services/rewards-service/rewards-service';
import { APIHelperContext } from './api-helper-context';
import ServiceHandler from '../services/service-handler';
import { useNavigateTo } from '../hooks/use-navigateTo';
export var RewardsContext = React.createContext({
    payload: null,
});
export var MODE;
(function (MODE) {
    MODE["EARN"] = "Earn";
    MODE["REDEEM"] = "Redeem";
})(MODE || (MODE = {}));
var translations = (_a = {},
    _a[MODE.EARN] = {
        en: 'Earn',
        fr: 'Obtention de',
    },
    _a[MODE.REDEEM] = {
        en: 'Redeem',
        fr: 'Échange de',
    },
    _a);
export var getModeText = function (mode) {
    if (ServiceHandler.ConfigService.isEnglish) {
        return translations[mode].en;
    }
    else if (ServiceHandler.ConfigService.isFrench) {
        return translations[mode].fr;
    }
    return translations[mode].en;
};
export var isPointsOnly = function (rewardOffer, paymentChoice) {
    if (paymentChoice === void 0) { paymentChoice = ''; }
    if (paymentChoice)
        return paymentChoice == 'P';
    return (rewardOffer === null || rewardOffer === void 0 ? void 0 : rewardOffer.pricePlan[0].paymentVariant) === 'P';
};
export var isPointsAndCash = function (rewardOffer, paymentChoice) {
    if (paymentChoice === void 0) { paymentChoice = ''; }
    if (paymentChoice)
        return paymentChoice == 'D';
    return (rewardOffer === null || rewardOffer === void 0 ? void 0 : rewardOffer.pricePlan[0].paymentVariant) === 'D';
};
export var isCash = function (rewardOffer, paymentChoice) {
    if (paymentChoice === void 0) { paymentChoice = ''; }
    if (paymentChoice)
        return paymentChoice == 'M';
    return (rewardOffer === null || rewardOffer === void 0 ? void 0 : rewardOffer.pricePlan[0].paymentVariant) === 'M';
};
ServiceHandler.ConfigService.initDataAttributes();
var getSortByMenu = function () {
    if (ServiceHandler.ConfigService.isEnglish) {
        return {
            all: 'Featured',
            low: 'Amount: Low to High',
            hi: 'Amount: High to Low',
        };
    }
    else {
        return {
            all: 'En vedette',
            low: 'Prix croissant',
            hi: 'Prix décroissant',
        };
    }
};
export var sortByMenu = getSortByMenu();
export var RewardsContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), rewards = _b[0], setRewards = _b[1];
    var _c = useState(null), categories = _c[0], setCategories = _c[1];
    var _d = useState(null), paymentChoice = _d[0], setpaymentChoice = _d[1];
    var _e = useState(0), pointsContribution = _e[0], setPointsContribution = _e[1];
    var _f = useState(0), moneyContribution = _f[0], setMoneyContribution = _f[1];
    var _g = useState(null), taxAmount = _g[0], setTaxAmount = _g[1];
    var _h = useState(null), taxAreaId = _h[0], setTaxAreaId = _h[1];
    var _j = useState(null), taxRate = _j[0], setTaxRate = _j[1];
    var _k = useState(['all']), filter = _k[0], setFilter = _k[1];
    var _l = useState([]), range = _l[0], setRange = _l[1];
    var _m = useState(sortByMenu.all), sortBy = _m[0], setSortBy = _m[1];
    var apiHelper = useContext(APIHelperContext)[0];
    var navigateTo = useNavigateTo().navigateTo;
    var ranges = [
        { code: '0k', description: ServiceHandler.ConfigService.isFrench ? '0 - 4 999' : '0 - 4,999', hi: 4999, low: 0 },
        { code: '5k', description: ServiceHandler.ConfigService.isFrench ? '5 000 - 24 999' : '5,000 - 24,999', hi: 24999, low: 5000 },
        { code: '25k', description: ServiceHandler.ConfigService.isFrench ? '25 000 - 49 999' : '25,000 - 49,999', hi: 49999, low: 25000 },
        { code: '50k', description: ServiceHandler.ConfigService.isFrench ? '50 000 - 99 999' : '50,000 - 99,999', hi: 99999, low: 50000 },
        { code: '100k', description: ServiceHandler.ConfigService.isFrench ? '100 000 - 199 999' : '100,000 - 199,999', hi: 199999, low: 100000 },
        { code: '200k', description: ServiceHandler.ConfigService.isFrench ? '200 000+' : '200,000+', hi: 99999999999, low: 200000 },
    ];
    var urlParams = new URLSearchParams(window.location.search);
    var modeType = urlParams.get('mode');
    var determineMode = function () {
        if (modeType == 'earn') {
            return MODE.EARN;
        }
        else {
            return MODE.REDEEM;
        }
    };
    var _o = useState(determineMode()), mode = _o[0], setMode = _o[1];
    useEffect(function () {
        if (!(rewards === null || rewards === void 0 ? void 0 : rewards.length) && ServiceHandler.AuthenticationService.getCatBundle())
            refreshRewards("".concat(ServiceHandler.ConfigService.isFPR ? 'FPR' : 'LAR', "_CAT"));
    }, [rewards, ServiceHandler.AuthenticationService.getCatBundle()]);
    var calcTotalCost = function (money) {
        var currencyFormat = new Intl.NumberFormat(ServiceHandler.ConfigService.LANGUAGE_COUNTRY, {
            currency: 'CAN',
            useGrouping: false,
        });
        return +currencyFormat.format(taxAmount + (moneyContribution || money - pointsContribution * 0.005));
    };
    var transform = function (rewards, categories) {
        var filtered = rewards.filter(function (rwrd) { return !rwrd.endDate || new Date(rwrd.endDate + 'T23:59:59.000-05:00').getTime() > new Date().getTime(); });
        var limited = [];
        filtered.forEach(function (rwrd) {
            var _a, _b;
            for (var i = 0; i < ranges.length; ++i) {
                var points = +((_a = rwrd.pricePlan[0].pointsText) === null || _a === void 0 ? void 0 : _a.replace(/\D/g, ''));
                if (points >= ranges[i].low && points <= ranges[i].hi) {
                    rwrd.range = ranges[i].code;
                    break;
                }
            }
            rwrd['type'] = ((_b = categories.find(function (c) { return c.code == rwrd.dashboardCategoryCode; })) === null || _b === void 0 ? void 0 : _b.type) || '';
            if (rwrd.dashboardLimitedTimeOffer) {
                var deepCopy = JSON.parse(JSON.stringify(rwrd));
                deepCopy.dashboardCategoryCode = 'limited';
                limited.push(deepCopy);
            }
        });
        var filtered2 = __spreadArray(__spreadArray([], filtered, true), limited, true);
        return filtered2.sort(function (a, b) { return +a.dashboardPosition - +b.dashboardPosition; });
    };
    var refreshRewards = function (rewardCategory) {
        var catalogService = new RewardsService();
        catalogService.request(apiHelper.handleError, rewardCategory).then(function (response) {
            if (response) {
                var rewards_1 = transform(response.rewards, response.categories);
                setRewards(rewards_1);
                if (rewards_1.find(function (r) { return r.dashboardCategoryCode == 'limited'; })) {
                    setCategories(__spreadArray(__spreadArray([
                        { code: 'all', description: ServiceHandler.ConfigService.isFrench ? 'Toute' : 'All' }
                    ], response.categories.sort(function (a, b) { return a.description.localeCompare(b.description); }), true), [
                        { code: 'limited', description: ServiceHandler.ConfigService.isFrench ? 'Offres à durée limitée' : 'Limited Time Offers' },
                    ], false));
                }
                else {
                    setCategories(__spreadArray([{ code: 'all', description: ServiceHandler.ConfigService.isFrench ? 'Toute' : 'All' }], response.categories, true));
                }
            }
        });
    };
    var fetchSDReward = function (categoryCode) {
        var handleFetchError = function () {
            navigateTo('/expired-reward', 'error=EXPIRED');
        };
        var catalogService = new RewardsService();
        catalogService.request(handleFetchError, categoryCode).then(function (response) {
            if (response.rewards.length == 0)
                return navigateTo('/expired-reward', 'error=EXPIRED');
            if (!response.rewards.find(function (rwrd) { return rwrd.attributes.find(function (a) { return a.code == 'isSDAvailable' && a.value == '1'; }); }))
                return navigateTo('/claimed-reward', 'error=CLAIMED');
            setCategories(__spreadArray(__spreadArray([], categories, true), response.categories, true));
            setRewards(__spreadArray(__spreadArray([], response.rewards, true), rewards, true));
        });
    };
    return (React.createElement(RewardsContext.Provider, { value: [
            {
                rewards: rewards,
                setRewards: setRewards,
                paymentChoice: paymentChoice,
                setpaymentChoice: setpaymentChoice,
                refreshRewards: refreshRewards,
                fetchSDReward: fetchSDReward,
                pointsContribution: pointsContribution,
                setPointsContribution: setPointsContribution,
                moneyContribution: moneyContribution,
                setMoneyContribution: setMoneyContribution,
                taxAmount: taxAmount,
                setTaxAmount: setTaxAmount,
                taxAreaId: taxAreaId,
                setTaxAreaId: setTaxAreaId,
                taxRate: taxRate,
                setTaxRate: setTaxRate,
                categories: categories,
                ranges: ranges,
                filter: filter,
                setFilter: setFilter,
                sortBy: sortBy,
                setSortBy: setSortBy,
                range: range,
                setRange: setRange,
                mode: mode,
                setMode: setMode,
                calcTotalCost: calcTotalCost,
            },
        ] }, children));
};
