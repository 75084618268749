export const footiesFPRFR = {
  MODEM:
    'Le modem doit être activé sur un téléphone intelligent au moyen de l’application FordPass dans les 60 jours suivant la date d’achat ou de location du véhicule et demeurer actif pour une période minimale de six mois après son activation. L’achat d’un véhicule non équipé d’un modem donne aussi des Points bonis de remerciement après l’inscription au programme. Visitez un concessionnaire participant pour plus de détails. L’application FordPass, compatible avec certaines plateformes de téléphones intelligents, est disponible par téléchargement. Des frais de messagerie texte et de transmission de données peuvent s’appliquer. Offre en vigueur jusqu’au 31 décembre 2025.',
  PURCHASE:
  "Avec tout achat ou toute location conclue au plus tard le 31 décembre 2024, les clients peuvent obtenir 5 000 Points bonis (« boni ») lors d’une première visite d’entretien au détail effectuée dans les 12 mois suivant la date d’achat ou de location du véhicule chez tous les concessionnaires Ford participants. Adhésion au programme Récompenses FordPass<sup>MD</sup> requise. Limite d’un boni par NIV. Offre valable à la première visite d’entretien au détail seulement. Les membres accumulent des Points chez un concessionnaire participant à l’achat au détail de pièces Ford, Motorcraft<sup>MD</sup> ou Omnicraft<sup>MC</sup> et de toute main-d’œuvre connexe. Aucun Point n’est accumulé à l’achat de pièces de marque autre que Ford ni pour l’entretien de ces pièces. Les services exécutés dans le cadre d’un Programme Protection-entretien Ford sont admissibles au boni. Offre valable pour l’achat au détail seulement. Offre non valable pour les visites d’entretien antérieures. Les Points du programme Récompenses FordPass ne sont pas monnayables et n’ont aucune valeur pécunaire. Aucun Point n’est accordé pour les taxes et les frais déboursés. Veuillez prévoir 30 jours avant que les Points du programme Récompenses FordPass ne soient déposés dans le compte d’un membre. Consultez votre concessionnaire participant ou les conditions du programme Récompenses FordPass pour en savoir plus sur l’expiration et l’échange des Points, le renoncement aux Points ou toute autre restriction visant les Points du programme Récompenses FordPass. Offre valable pour les véhicules achetés ou loués au plus tard le 31 décembre 2024.",
  EARN:
  "Les membres accumuleront 5 Points Récompenses FordPass par dollar dépensé chez tout concessionnaire participant à l’achat au détail de pièces Ford, Motorcraft<sup>MD</sup> ou Omnicraft<sup>MC</sup> ainsi que pour la main-d’œuvre qui s’y rattache. Aucun Point n’est octroyé à l’achat de pièces de marque autre que Ford ni pour l’entretien de telles pièces. Aucun Point n’est accordé pour les taxes et les frais déboursés. Consultez votre concessionnaire participant ou les conditions du programme Récompenses FordPass pour en savoir plus sur l’expiration et l’échange des Points, le renoncement aux Points ou toute autre restriction visant l’utilisation des Points Récompenses FordPass.",
  REDEEM:
    'Les Points ne sont pas monnayables et n’ont aucune valeur pécunaire. Les valeurs d’échange sont approximatives et varient selon les produits et services échangés. Consultez votre concessionnaire participant ou les conditions générales du programme Récompenses FordPass pour de plus amples renseignements sur l’expiration et l’échange des Points, le renoncement aux Points ou toute autre restriction visant l’utilisation des Points.',
};
