import React, { useState } from 'react';
import styles from './combo.module.scss';
import ComboElement from './combo-element';
import { FdsChevron } from '../chevron/fds-chevron';
import { InputWithLabel } from '../Input-with-label/input-with-label';
import { ActivityIndicator } from '../activity-indicator/activity-indicator';
export var TypeaheadComboControl = function (_a) {
    var id = _a.id, dataSource = _a.dataSource, label = _a.label, setSelected = _a.setSelected, getId = _a.getId, getName = _a.getName, getDesc = _a.getDesc, selected = _a.selected, ariaDescribedBy = _a.ariaDescribedBy, ariaInvalid = _a.ariaInvalid;
    var _b = useState([]), filteredComboItems = _b[0], setFilteredComboItems = _b[1];
    var _c = useState(-1), active = _c[0], setActive = _c[1];
    var handleClick = function (evnt, id) {
        evnt.preventDefault();
        var comboItem = filteredComboItems.find(function (comboItem) { return (comboItem.id || getId(comboItem)) === id; });
        var combo = evnt.target;
        while (combo && combo.nodeName != 'LI')
            combo = combo.parentElement;
        var input = combo.parentElement.parentElement.getElementsByTagName('INPUT')[0];
        input.value = comboItem.name;
        handleCloseDropdown();
        setSelected(comboItem);
    };
    var filterComboItems = function (inputValue) {
        var comboItemFilter = inputValue.toUpperCase().replace(/[^A-Z ]/g, '');
        var results = dataSource;
        results = results.filter(function (comboItem) { return comboItem.search.includes(comboItemFilter); });
        return results;
    };
    var handlecomboItemInputChange = function (evnt) {
        if (evnt.nativeEvent.inputType == 'deleteContentBackward' && filterComboItems.length < 2) {
            while (filterComboItems(evnt.target.value).length == 1)
                evnt.target.value = evnt.target.value.slice(0, -1);
        }
        var results = filterComboItems(evnt.target.value);
        setActive(-1);
        evnt.target.setCustomValidity(results.length > 0 ? '' : 'No Matches Found');
        if (results.length == 1) {
            evnt.target.value = results[0].name;
            setSelected(results[0]);
        }
        else {
            setSelected(null);
        }
        setFilteredComboItems(results);
    };
    var handleCloseDropdown = function () {
        setFilteredComboItems([]);
        var inputs = Array.from(document.querySelectorAll('input'));
        var index = inputs.findIndex(function (el) { return el == document.getElementById(id); });
        setTimeout(function () { return inputs[index + 1].focus(); }, 1);
    };
    var handleFocus = function (evnt) {
        handlecomboItemInputChange(evnt);
    };
    var handleInputClick = function (evnt) {
        var results = filterComboItems(evnt.target.value);
        setFilteredComboItems(results);
    };
    var handleKeyPress = function (evnt) {
        if (evnt.key === 'Escape')
            handleCloseDropdown();
        if (evnt.key == 'ArrowDown')
            setActive(Math.min(active + 1, filteredComboItems.length - 1));
        if (evnt.key == 'ArrowUp')
            setActive(Math.max(active - 1, -1));
        if (evnt.key === 'Enter' && active > -1) {
            document.getElementById(id).value = filteredComboItems[active].name;
            setSelected(filteredComboItems[active]);
            handleCloseDropdown();
        }
    };
    return (React.createElement(InputWithLabel, { id: id, label: label, onChange: handlecomboItemInputChange, onBlur: handleCloseDropdown, onFocus: handleFocus, defaultValue: selected === null || selected === void 0 ? void 0 : selected.name, onClick: handleInputClick, onKeyDown: handleKeyPress, disabled: (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length) <= 1, ariaDescribedBy: ariaDescribedBy, ariaInvalid: ariaInvalid },
        (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length) > 1 ? (
        // <FdsChevron type='unfilled' direction='down' className={styles.dropdownChevron} />
        React.createElement(FdsChevron, { direction: 'down', className: styles.dropdownChevron })) : (React.createElement(ActivityIndicator, { className: styles.droddownSpinner, size: 'S' })),
        React.createElement("div", { className: styles.dropDown }, (dataSource === null || dataSource === void 0 ? void 0 : dataSource.length) > 1 &&
            filteredComboItems.map(function (item, index) { return (React.createElement(ComboElement, { key: item.id || getId(item), id: item.id || getId(item), title: item.name || getName(item), desc: item.desc || getDesc(item), handleClick: handleClick, active: active == index })); }))));
};
